// src/metadata/index.js

// Import all company metadata (1 to 23)
import company1 from './companies/1_metadata.json';
import company2 from './companies/2_metadata.json';
import company3 from './companies/3_metadata.json';
import company4 from './companies/4_metadata.json';
import company5 from './companies/5_metadata.json';
import company6 from './companies/6_metadata.json';
import company7 from './companies/7_metadata.json';
import company8 from './companies/8_metadata.json';
import company9 from './companies/9_metadata.json';
import company10 from './companies/10_metadata.json';
import company11 from './companies/11_metadata.json';
import company12 from './companies/12_metadata.json';
import company13 from './companies/13_metadata.json';
import company14 from './companies/14_metadata.json';
import company15 from './companies/15_metadata.json';
import company16 from './companies/16_metadata.json';
import company17 from './companies/17_metadata.json';
import company18 from './companies/18_metadata.json';
import company19 from './companies/19_metadata.json';
import company20 from './companies/20_metadata.json';
import company21 from './companies/21_metadata.json';
import company22 from './companies/22_metadata.json';
import company23 from './companies/23_metadata.json';

// Import all people metadata (1 to 31)
import person1 from './people/1_metadata.json';
import person2 from './people/2_metadata.json';
import person3 from './people/3_metadata.json';
import person4 from './people/4_metadata.json';
import person5 from './people/5_metadata.json';
import person6 from './people/6_metadata.json';
import person7 from './people/7_metadata.json';
import person8 from './people/8_metadata.json';
import person9 from './people/9_metadata.json';
import person10 from './people/10_metadata.json';
import person11 from './people/11_metadata.json';
import person12 from './people/12_metadata.json';
import person13 from './people/13_metadata.json';
import person14 from './people/14_metadata.json';
import person15 from './people/15_metadata.json';
import person16 from './people/16_metadata.json';
import person17 from './people/17_metadata.json';
import person18 from './people/18_metadata.json';
import person19 from './people/19_metadata.json';
import person20 from './people/20_metadata.json';
import person21 from './people/21_metadata.json';
import person22 from './people/22_metadata.json';
import person23 from './people/23_metadata.json';
import person24 from './people/24_metadata.json';
import person25 from './people/25_metadata.json';
import person26 from './people/26_metadata.json';
import person27 from './people/27_metadata.json';
import person28 from './people/28_metadata.json';
import person29 from './people/29_metadata.json';
import person30 from './people/30_metadata.json';
import person31 from './people/31_metadata.json';

// Combine all metadata into one array
const metadataList = [
  company1, company2, company3, company4, company5, company6, company7,
  company8, company9, company10, company11, company12, company13,
  company14, company15, company16, company17, company18, company19,
  company20, company21, company22, company23, // Companies

  person1, person2, person3, person4, person5, person6, person7,
  person8, person9, person10, person11, person12, person13,
  person14, person15, person16, person17, person18, person19,
  person20, person21, person22, person23, person24, person25,
  person26, person27, person28, person29, person30, person31 // People
];

export default metadataList;
