import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import './NetworkVisualization.css';
import metadataList from '../metadata';

const resolveImagePath = (imageUrl, nodeType) => {
  if (!imageUrl) return null;
  if (imageUrl.startsWith('http') || imageUrl.startsWith('data:')) {
    return imageUrl;
  }
  const cleanPath = imageUrl.replace(/^\/+/, '');
  const basePath = nodeType === 'person' ? 'people_images' : 'companies_images';
  return `${process.env.PUBLIC_URL}/${basePath}/${cleanPath}`;
};

const NetworkVisualization = () => {
  const mountRef = useRef(null);
  const rendererRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const controlsRef = useRef(null);
  
  const [selectedNode, setSelectedNode] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(4);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [infoBoxContent, setInfoBoxContent] = useState('');
  const [infoBoxPosition, setInfoBoxPosition] = useState({ x: 0, y: 0 });
  const [loadingMessage, setLoadingMessage] = useState('Search for a node to begin visualization...');

  const onWindowResize = useCallback(() => {
    if (cameraRef.current && rendererRef.current) {
      cameraRef.current.aspect = window.innerWidth / window.innerHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [onWindowResize]);
      
    useEffect(() => {
      if (!mountRef.current) return;

      let scene, camera, renderer, controls;
      let nodes = new Map();
      let edges = new Map();
      let graph = new Map();
      let animationFrameId;
      let uniqueRelationships = new Set();
      let relationshipColors = new Map();

      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();
      let isDragging = false;
      let mouseDownTime = 0;
      let mouseDownPosition = { x: 0, y: 0 };
      let scale = 500;
  
      const init = async () => {
        try {
          scene = new THREE.Scene();
          scene.background = new THREE.Color(0x1a1a1a);
  
          renderer = new THREE.WebGLRenderer({ antialias: true });
          renderer.setSize(window.innerWidth, window.innerHeight);
          renderer.setPixelRatio(window.devicePixelRatio);
          mountRef.current.appendChild(renderer.domElement);
  
          camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 5000);
          camera.position.set(0, 0, 1500);
  
          controls = new OrbitControls(camera, renderer.domElement);
          controls.minPolarAngle = 0;
          controls.maxPolarAngle = Math.PI;
          controls.enableDamping = true;
          controls.dampingFactor = 0.05;
          controls.rotateSpeed = 0.5;
          controls.target.set(0, 0, 0);
  
          const ambientLight = new THREE.AmbientLight(0x404040, 0.7);
          scene.add(ambientLight);
          const mainLight = new THREE.DirectionalLight(0xffffff, 1);
          mainLight.position.set(100, 100, 100);
          scene.add(mainLight);
          const backLight = new THREE.DirectionalLight(0x404040, 0.5);
          backLight.position.set(-100, -100, -100);
          scene.add(backLight);
  
          window.addEventListener('resize', onWindowResize);
          window.addEventListener('mousedown', onMouseDown);
          window.addEventListener('mouseup', onMouseUp);
          window.addEventListener('mousemove', onMouseMove);
  
          animate();
          await loadNetworkData();
        } catch (error) {
          console.error('Initialization error:', error);
          setLoadingMessage('Error initializing visualization. Please refresh the page.');
        }
      };
  
      const onWindowResize = () => {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      };
     
      const onMouseDown = (event) => {
        isDragging = false;
        mouseDownTime = Date.now();
        mouseDownPosition = { x: event.clientX, y: event.clientY };
      };
  
      const onMouseMove = (event) => {
        const moveThreshold = 5;
        if (
          Math.abs(event.clientX - mouseDownPosition.x) > moveThreshold ||
          Math.abs(event.clientY - mouseDownPosition.y) > moveThreshold
        ) {
          isDragging = true;
        }
      };
  
      const onMouseUp = (event) => {
        const clickDuration = Date.now() - mouseDownTime;
        if (!isDragging && clickDuration < 500) {
          onMouseClick(event);
        }
        isDragging = false;
      };
  
      const onMouseClick = (event) => {
        try {
          event.preventDefault();
          mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
          mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
          raycaster.setFromCamera(mouse, camera);
          const intersects = raycaster.intersectObjects(scene.children, true);
          let clickedNode = null;
          for (const intersect of intersects) {
            let obj = intersect.object;
            while (obj.parent && !obj.userData.id) {
              obj = obj.parent;
            }
            if (obj.userData.id) {
              clickedNode = obj;
              break;
            }
          }
          if (clickedNode) {
            showInfoBox(clickedNode);
          } else {
            hideInfoBox();
          }
        } catch (error) {
          console.error('Mouse click error:', error);
        }
      };
  
      const showInfoBox = (node) => {
        try {
          if (!node || !node.userData || !node.userData.metadata) {
            console.error('Invalid node data:', node);
            return;
          }
  
          const metadata = node.userData.metadata;
          let htmlContent = `<strong>${metadata.namn || 'Unknown'}</strong><br>`;
  
          // Add more metadata details as needed
  
          setInfoBoxContent(htmlContent);
  
          const vector = new THREE.Vector3();
          node.getWorldPosition(vector);
          vector.project(camera);
          const x = (vector.x * 0.5 + 0.5) * window.innerWidth;
          const y = (-vector.y * 0.5 + 0.5) * window.innerHeight;
  
          const infoBoxWidth = 300;
          const infoBoxHeight = 200;
          let adjustedX = x;
          let adjustedY = y;
  
          if (x + infoBoxWidth > window.innerWidth) {
            adjustedX = window.innerWidth - infoBoxWidth - 10;
          }
          if (y + infoBoxHeight > window.innerHeight) {
            adjustedY = window.innerHeight - infoBoxHeight - 10;
          }
  
          setInfoBoxPosition({ x: adjustedX, y: adjustedY });
        } catch (error) {
          console.error('Error showing info box:', error);
          setInfoBoxContent('');
        }
      };
  
      const hideInfoBox = () => {
        setInfoBoxContent('');
      };
  
      const animate = () => {
        try {
          animationFrameId = requestAnimationFrame(animate);
          if (controls) controls.update();
  
          nodes.forEach((nodeGroup) => {
            nodeGroup.children.forEach((child) => {
              if (child instanceof THREE.Sprite) {
                child.quaternion.copy(camera.quaternion);
              }
            });
          });
  
          if (renderer && scene && camera) {
            renderer.render(scene, camera);
          }
        } catch (error) {
          console.error('Animation error:', error);
        }
      };
  
      const loadNetworkData = async () => {
        try {
          if (!selectedNode) {
            setLoadingMessage('Search for a node to begin visualization...');
            return;
          }
  
          if (!metadataList || metadataList.length === 0) {
            setLoadingMessage('No metadata available to visualize.');
            return;
          }
  
          console.log('Loading network data for node:', selectedNode);
          setLoadingMessage('Loading network data...');
  
          // Clear existing data
          nodes.clear();
          edges.clear();
          graph.clear();
          uniqueRelationships.clear();
          relationshipColors.clear();
  
          // Remove all objects except lights
          scene.children.slice().forEach((child) => {
            if (!(child instanceof THREE.AmbientLight || child instanceof THREE.DirectionalLight)) {
              scene.remove(child);
            }
          });
  
          const G = new Map();
          const idToNode = new Map();
  
          metadataList.forEach((data) => {
            const nodeId = String(data.id);
            idToNode.set(nodeId, data);
            G.set(nodeId, { data: data, edges: [] });
          });
  
          metadataList.forEach((data) => {
            const nodeId = String(data.id);
            const connections = data.connections || [];
            connections.forEach((conn) => {
              const targetId = String(conn.id);
              const relationshipType = conn.relationship_type || 'Related';
              if (G.has(targetId)) {
                G.get(nodeId).edges.push({ target: targetId, relationshipType });
                uniqueRelationships.add(relationshipType);
              }
            });
          });
  
          const selectedIdStr = String(selectedNode);
          if (!G.has(selectedIdStr)) {
            console.warn(`Selected node ID "${selectedIdStr}" not found in metadata.`);
            setLoadingMessage('Selected node not found in data.');
            return;
          }
  
          const nodesToInclude = new Set();
          const distances = new Map();
          const queue = [];
          distances.set(selectedIdStr, 0);
          queue.push(selectedIdStr);
  
          while (queue.length > 0) {
            const current = queue.shift();
            const currentDistance = distances.get(current);
            if (currentDistance > currentLevel) continue;
            nodesToInclude.add(current);
            const neighbors = G.get(current).edges || [];
            neighbors.forEach((neighbor) => {
              const neighborId = neighbor.target;
              if (!distances.has(neighborId)) {
                distances.set(neighborId, currentDistance + 1);
                queue.push(neighborId);
              }
            });
          }
  
          const nodesArray = Array.from(nodesToInclude).map((nodeId) => ({
            id: nodeId,
            data: G.get(nodeId).data,
          }));
  
          // Use spring layout for positioning
          const positions = computeSpringLayout(nodesArray);
  
          const nodePromises = nodesArray.map(async (nodeObj) => {
            const nodeData = nodeObj.data;
            const nodeId = nodeObj.id;
            const position = positions.get(nodeId);
            const isSelected = nodeId === selectedIdStr;
            const scaleFactor = isSelected ? 2.0 : 1.0;
  
            const nodeInfo = {
              id: nodeId,
              name: nodeData.namn,
              type: nodeData.type,
              x: position.x * scale,
              y: position.y * scale,
              z: position.z * scale,
              image_url: resolveImagePath(nodeData.image_url, nodeData.type),
              is_selected: isSelected,
              scale_factor: scaleFactor,
              metadata: nodeData,
            };
  
            try {
              const nodeGroup = await createNodeSprite(nodeInfo);
              if (nodeGroup) {
                nodes.set(nodeId, nodeGroup);
                scene.add(nodeGroup);
                graph.set(nodeId, []);
              }
            } catch (error) {
              console.error('Error creating node:', nodeId, error);
            }
          });
  
          await Promise.all(nodePromises);
  
          nodesArray.forEach((nodeObj) => {
            const nodeId = nodeObj.id;
            const nodeEdges = G.get(nodeId).edges;
            nodeEdges.forEach((edge) => {
              const targetId = edge.target;
              if (!nodesToInclude.has(targetId)) return;
              if (edges.has(`${nodeId}-${targetId}`) || edges.has(`${targetId}-${nodeId}`)) return;
  
              const sourceNode = nodes.get(nodeId);
              const targetNode = nodes.get(targetId);
              if (sourceNode && targetNode) {
                const edgeGroup = createEdge(sourceNode, targetNode, edge.relationshipType);
                if (edgeGroup) {
                  edges.set(`${nodeId}-${targetId}`, edgeGroup);
                  scene.add(edgeGroup);
                  graph.get(nodeId).push(targetId);
                  graph.get(targetId).push(nodeId);
                }
              }
            });
          });
  
          // Center and scale the camera to fit the network
          adjustCameraToFitNetwork();
  
          updateVisibility(selectedNode);
          setLoadingMessage(null);
        } catch (error) {
          console.error('Error loading network data:', error);
          setLoadingMessage('Error loading network data. Please try again.');
        }
      };
  
      const computeSpringLayout = (nodesArray) => {
        // Implement a simple 3D spring layout algorithm
        const positions = new Map();
        const iterations = 50;
        const k = 1; // Spring constant
        const repulsion = 5000;
  
        // Initialize positions randomly
        nodesArray.forEach((nodeObj) => {
          positions.set(nodeObj.id, {
            x: Math.random() * 100 - 50,
            y: Math.random() * 100 - 50,
            z: Math.random() * 100 - 50,
          });
        });
  
        for (let i = 0; i < iterations; i++) {
          // Calculate forces
          const forces = new Map();
  
          nodesArray.forEach((nodeA) => {
            const posA = positions.get(nodeA.id);
            let force = { x: 0, y: 0, z: 0 };
  
            // Repulsion
            nodesArray.forEach((nodeB) => {
              if (nodeA.id === nodeB.id) return;
              const posB = positions.get(nodeB.id);
              const dx = posA.x - posB.x;
              const dy = posA.y - posB.y;
              const dz = posA.z - posB.z;
              const distance = Math.sqrt(dx * dx + dy * dy + dz * dz) + 0.01;
              const repulsiveForce = repulsion / (distance * distance);
              force.x += (dx / distance) * repulsiveForce;
              force.y += (dy / distance) * repulsiveForce;
              force.z += (dz / distance) * repulsiveForce;
            });
  
            // Attraction
            const neighbors = graph.get(nodeA.id) || [];
            neighbors.forEach((neighborId) => {
              const posB = positions.get(neighborId);
              const dx = posA.x - posB.x;
              const dy = posA.y - posB.y;
              const dz = posA.z - posB.z;
              const distance = Math.sqrt(dx * dx + dy * dy + dz * dz) + 0.01;
              const attractiveForce = -k * distance;
              force.x += (dx / distance) * attractiveForce;
              force.y += (dy / distance) * attractiveForce;
              force.z += (dz / distance) * attractiveForce;
            });
  
            forces.set(nodeA.id, force);
          });
  
          // Update positions
          nodesArray.forEach((nodeObj) => {
            const pos = positions.get(nodeObj.id);
            const force = forces.get(nodeObj.id);
            pos.x += force.x * 0.01;
            pos.y += force.y * 0.01;
            pos.z += force.z * 0.01;
          });
        }
  
        return positions;
      };
  
      const adjustCameraToFitNetwork = () => {
        // Adjust camera to fit the scene
        const bbox = new THREE.Box3();
        nodes.forEach((node) => {
          bbox.expandByObject(node);
        });
  
        const centerVec = new THREE.Vector3();
        bbox.getCenter(centerVec);
  
        const sizeVec = new THREE.Vector3();
        bbox.getSize(sizeVec);
        const maxDim = Math.max(sizeVec.x, sizeVec.y, sizeVec.z);
  
        const fov = camera.fov * (Math.PI / 180);
        const cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2));
  
        camera.position.set(centerVec.x, centerVec.y, cameraZ * 1.5);
        camera.lookAt(centerVec);
        controls.target.copy(centerVec);
        controls.update();
      };
  
      const createNodeSprite = async (node) => {
        return new Promise((resolve) => {
          const group = new THREE.Group();
  
          const canvas = document.createElement('canvas');
          const size = 256 * 2;
          canvas.width = size;
          canvas.height = size;
          const ctx = canvas.getContext('2d');
  
          const createPlaceholder = () => {
            ctx.clearRect(0, 0, size, size);
            const bgColor = node.type === 'person' ? '#4CAF50' : '#2196F3';
            ctx.fillStyle = bgColor;
  
            if (node.type === 'person') {
              ctx.beginPath();
              ctx.arc(size / 2, size / 2, size / 2 - 15, 0, 2 * Math.PI);
              ctx.fill();
  
              ctx.fillStyle = '#FFFFFF';
              ctx.font = 'bold 120px Arial';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              const initials = node.name
                ?.split(' ')
                .map((n) => n[0])
                .slice(0, 2)
                .join('') || '?';
              ctx.fillText(initials, size / 2, size / 2);
            } else {
              ctx.fillRect(15, 15, size - 30, size - 30);
              ctx.fillStyle = '#FFFFFF';
              ctx.font = 'bold 120px Arial';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              const initial = node.name?.[0] || 'C';
              ctx.fillText(initial, size / 2, size / 2);
            }
          };
  
          createPlaceholder();
          const texture = new THREE.CanvasTexture(canvas);
          const spriteMaterial = new THREE.SpriteMaterial({
            map: texture,
            transparent: true,
          });
  
          const sprite = new THREE.Sprite(spriteMaterial);
          const baseScale = node.scale_factor * 40; // Increased scale for visibility
          sprite.scale.set(baseScale, baseScale, 1);
          sprite.renderOrder = 1;
          group.add(sprite);
  
          const labelSprite = createTextSprite(
            node.name || 'Unknown',
            '#ffffff',
            node.type === 'person' ? '#4CAF50' : '#2196F3',
            true
          );
          if (labelSprite) {
            labelSprite.position.y = -(sprite.scale.y / 2 + labelSprite.scale.y / 2 + 5);
            labelSprite.renderOrder = 2;
            group.add(labelSprite);
          }
  
          group.position.set(node.x, node.y, node.z);
          group.userData = {
            id: node.id,
            name: node.name,
            type: node.type,
            isNode: true,
            distanceLabel: null,
            is_selected: node.is_selected,
            metadata: node.metadata,
          };
  
          if (node.image_url) {
            const image = new Image();
            image.crossOrigin = 'Anonymous';
  
            image.onload = () => {
              try {
                ctx.clearRect(0, 0, size, size);
  
                if (node.type === 'person') {
                  ctx.save();
                  ctx.beginPath();
                  ctx.arc(size / 2, size / 2, size / 2 - 15, 0, 2 * Math.PI);
                  ctx.clip();
                  ctx.drawImage(image, 15, 15, size - 30, size - 30);
                  ctx.restore();
  
                  ctx.strokeStyle = '#4CAF50';
                  ctx.lineWidth = 10;
                  ctx.beginPath();
                  ctx.arc(size / 2, size / 2, size / 2 - 15, 0, 2 * Math.PI);
                  ctx.stroke();
                } else {
                  ctx.drawImage(image, 15, 15, size - 30, size - 30);
                  ctx.strokeStyle = '#2196F3';
                  ctx.lineWidth = 10;
                  ctx.strokeRect(15, 15, size - 30, size - 30);
                }
  
                texture.needsUpdate = true;
              } catch (error) {
                console.error('Error updating image:', error);
                createPlaceholder();
                texture.needsUpdate = true;
              }
            };
  
            image.onerror = () => {
              console.warn(`Failed to load image for node ${node.id}:`, node.image_url);
              createPlaceholder();
              texture.needsUpdate = true;
            };
  
            image.src = node.image_url;
          }
  
          resolve(group);
        });
      };
  
      const createTextSprite = (text, color, outlineColor = '#000000', outline = false) => {
        try {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const fontSize = 48 * 2;
          ctx.font = `bold ${fontSize}px Arial`;
          const textWidth = ctx.measureText(text).width;
          canvas.width = textWidth + 40;
          canvas.height = fontSize + 20;
          ctx.font = `bold ${fontSize}px Arial`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          if (outline) {
            ctx.strokeStyle = outlineColor;
            ctx.lineWidth = 8;
            ctx.strokeText(text, canvas.width / 2, canvas.height / 2);
          }
          ctx.fillStyle = color;
          ctx.fillText(text, canvas.width / 2, canvas.height / 2);
          const texture = new THREE.CanvasTexture(canvas);
          const spriteMaterial = new THREE.SpriteMaterial({
            map: texture,
            transparent: true,
          });
          const sprite = new THREE.Sprite(spriteMaterial);
          sprite.scale.set(canvas.width / 10, canvas.height / 10, 1);
          return sprite;
        } catch (error) {
          console.error('Error creating text sprite:', error);
          return null;
        }
      };
  
      const getEdgeColor = (relationshipType) => {
        try {
          if (!relationshipColors.has(relationshipType)) {
            const colors = [
              0xffa500, 0x0000ff, 0x008000, 0xff0000, 0x800080,
              0x00ffff, 0xff00ff, 0x808000, 0x00ff00, 0x800000,
            ];
            const index = relationshipColors.size % colors.length;
            relationshipColors.set(relationshipType, new THREE.Color(colors[index]));
          }
          return relationshipColors.get(relationshipType);
        } catch (error) {
          console.error('Error getting edge color:', error);
          return new THREE.Color(0xffffff);
        }
      };
  
      const createEdge = (sourceNode, targetNode, relationshipType) => {
        try {
          const sourcePos = sourceNode.position;
          const targetPos = targetNode.position;
          const points = [
            new THREE.Vector3(sourcePos.x, sourcePos.y, sourcePos.z),
            new THREE.Vector3(targetPos.x, targetPos.y, targetPos.z),
          ];
  
          const color = getEdgeColor(relationshipType);
          const mainGeometry = new THREE.BufferGeometry().setFromPoints(points);
          const mainMaterial = new THREE.LineBasicMaterial({
            color: color,
            transparent: true,
            opacity: 1.0,
            linewidth: 2,
          });
          const line = new THREE.Line(mainGeometry, mainMaterial);
          line.renderOrder = 0;
  
          const edgeGroup = new THREE.Group();
          edgeGroup.add(line);
  
          // Edge label
          const midPoint = new THREE.Vector3().addVectors(sourcePos, targetPos).multiplyScalar(0.5);
          const labelSprite = createTextSprite(
            relationshipType,
            '#ffffff',
            '#' + color.getHexString(),
            true
          );
          if (labelSprite) {
            labelSprite.position.copy(midPoint);
            labelSprite.renderOrder = 2;
            edgeGroup.add(labelSprite);
          }
  
          edgeGroup.userData = {
            relationship_type: relationshipType,
            sourceId: sourceNode.userData.id,
            targetId: targetNode.userData.id,
          };
  
          return edgeGroup;
        } catch (error) {
          console.error('Error creating edge:', error);
          return null;
        }
      };
  
      const updateVisibility = (selectedId) => {
        try {
          if (!selectedId) {
            nodes.forEach((node) => {
              node.visible = false;
            });
            edges.forEach((edge) => {
              edge.visible = false;
            });
            return;
          }
  
          const selectedIdStr = String(selectedId);
          const distances = computeDistances(selectedIdStr);
  
          nodes.forEach((nodeGroup, nodeId) => {
            const distance = distances.get(String(nodeId));
            const shouldBeVisible = distance !== undefined && distance <= currentLevel;
            nodeGroup.visible = shouldBeVisible;
          });
  
          edges.forEach((edgeGroup) => {
            const sourceId = String(edgeGroup.userData.sourceId);
            const targetId = String(edgeGroup.userData.targetId);
            const sourceDistance = distances.get(sourceId);
            const targetDistance = distances.get(targetId);
            const shouldBeVisible =
              sourceDistance !== undefined &&
              targetDistance !== undefined &&
              Math.max(sourceDistance, targetDistance) <= currentLevel;
            edgeGroup.visible = shouldBeVisible;
          });
        } catch (error) {
          console.error('Error updating visibility:', error);
        }
      };
  
      const computeDistances = (startId) => {
        try {
          const distances = new Map();
          const queue = [];
          const startIdStr = String(startId);
          distances.set(startIdStr, 0);
          queue.push(startIdStr);
  
          while (queue.length > 0) {
            const current = queue.shift();
            const currentDistance = distances.get(current);
            const neighbors = graph.get(current) || [];
            neighbors.forEach((neighbor) => {
              const neighborStr = String(neighbor);
              if (!distances.has(neighborStr)) {
                distances.set(neighborStr, currentDistance + 1);
                queue.push(neighborStr);
              }
            });
          }
          return distances;
        } catch (error) {
          console.error('Error computing distances:', error);
          return new Map();
        }
      };
  
      init();

      return () => {
        window.removeEventListener('mousedown', onMouseDown);
        window.removeEventListener('mouseup', onMouseUp);
        window.removeEventListener('mousemove', onMouseMove);
        cancelAnimationFrame(animationFrameId);
        if (renderer) {
          renderer.dispose();
        }
      };
    }, [selectedNode, currentLevel]);
  
    const handleSearchInput = (e) => {
      const query = e.target.value.toLowerCase();
      setSearchQuery(query);
  
      if (query.length > 0) {
        const results = metadataList.filter((data) => {
          return (
            data.namn.toLowerCase().includes(query) ||
            (data.personnummer && data.personnummer.includes(query)) ||
            (data.orgnummer && data.orgnummer.includes(query))
          );
        });
        setSearchResults(results);
      } else {
        setSearchResults([]);
      }
    };
  
    const handleSearchResultClick = (result) => {
      console.log('Selected node:', result.id);
  
      // Reset size of old selected node
      setSelectedNode((prevSelectedNode) => {
        if (prevSelectedNode && prevSelectedNode !== result.id) {
          // Reset the scale of the previous selected node
          // This will be handled in the node creation logic
        }
        return result.id;
      });
  
      setSearchQuery('');
      setSearchResults([]);
    };
  
    const handleLevelButtonClick = (level) => {
      setCurrentLevel(level);
    };
  
    return (
      <div className="network-visualization-container">
        {loadingMessage && <div className="loading">{loadingMessage}</div>}
  
        <div className="search-container">
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              className="search-input"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchInput}
            />
            {searchResults.length > 0 && (
              <div className="search-results">
                {searchResults.map((result) => (
                  <div
                    key={result.id}
                    className="search-result"
                    onClick={() => handleSearchResultClick(result)}
                  >
                    {result.namn} ({result.type})
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="level-buttons">
            {[1, 2, 3, 4].map((level) => (
              <button
                key={level}
                className={`level-button ${currentLevel === level ? 'active' : ''}`}
                onClick={() => handleLevelButtonClick(level)}
              >
                {level}
              </button>
            ))}
          </div>
        </div>
  
        {infoBoxContent && (
          <div
            className="info-box"
            style={{ left: infoBoxPosition.x, top: infoBoxPosition.y }}
            dangerouslySetInnerHTML={{ __html: infoBoxContent }}
          />
        )}
  
        <div
          ref={mountRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    );
  };
  
  export default NetworkVisualization;