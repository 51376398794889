import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './StatisticsFeedback.css';

const Section = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);
  return (
    <div className="feedback-section">
      <button 
        className="section-header" 
        onClick={() => setIsExpanded(!isExpanded)}
        type="button"
        aria-expanded={isExpanded}
        aria-controls={`section-content-${title}`}
      >
        <h3 id={`section-header-${title}`}>{title}</h3>
        {isExpanded ? <ChevronUp size={20} aria-label="Collapse section" /> : <ChevronDown size={20} aria-label="Expand section" />}
      </button>
      {isExpanded && (
        <div 
          id={`section-content-${title}`} 
          className="section-content"
          role="region"
          aria-labelledby={`section-header-${title}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const ImprovementAreas = ({ courseType }) => {
  const statisticsImprovements = {
    courseStructure: {
      title: "Course Structure & Organization",
      items: [
        "Establish a single source of truth in Canvas modules for course materials, announcements, and updates",
        "Provide comprehensive assignment guidelines and grading rubrics at course start",
        "Implement transparent quiz schedule with clear passing requirements and point distribution",
        "Ensure stronger alignment between lectures, exercises, and exams with adjusted content volume"
      ]
    },
    teachingSupport: {
      title: "Teaching & Learning Support",
      items: [
        "Incorporate more real-world examples and practical applications throughout the course",
        "Provide additional practice exercises that align with lecture content",
        "Offer structured exam preparation with practice questions and review sessions",
        "Implement structured approach to academic paper reading and seminar participation"
      ]
    },
    contentDelivery: {
      title: "Content Delivery",
      items: [
        "Strike better balance between theoretical foundations and practical applications",
        "Develop step-by-step guides and worked examples for challenging topics",
        "Create clearer connections between different course components and concepts",
        "Implement gradual introduction of complex concepts with foundational understanding"
      ]
    },
    assessment: {
      title: "Assessment & Feedback",
      items: [
        "Establish consistent and timely communication about assessment deadlines and requirements",
        "Implement thorough quality control for quiz questions and instructions",
        "Provide comprehensive practice materials aligned with exam format",
        "Implement regular feedback opportunities throughout the course duration"
      ]
    }
  };

  const aiImprovements = {
    courseStructure: {
      title: "Course Structure & Organization",
      items: [
        "Implement a consistent schedule for releasing lecture materials at least 48 hours before each session, enabling students to review and prepare effectively",
        "Create a structured document repository in Canvas with clear organization of course materials, reading requirements, and up-to-date assignment specifications",
        "Establish fixed deadlines at the start of the course, with a change management process requiring minimum 2-week notice for any modifications",
        "Develop comprehensive project guidelines that outline all deliverables, evaluation criteria, and milestone expectations from day one"
      ]
    },
    teachingSupport: {
      title: "Teaching & Learning Support",
      items: [
        "Structure seminar sessions with clear agendas, discussion topics, and instructor-guided breakout sessions to maximize peer learning opportunities",
        "Ensure thorough instructor preparation for each lecture, incorporating interactive elements and real-time concept checks to gauge understanding",
        "Institute regular office hours dedicated to assignment clarification and project guidance, with guaranteed response times for student inquiries",
        "Create collaborative opportunities between AI and statistics courses where concepts overlap, enabling students to see practical connections"
      ]
    },
    contentDelivery: {
      title: "Content Delivery",
      items: [
        "Adjust lecture pacing to include dedicated time for questions and discussion, ensuring complex topics receive adequate coverage",
        "Implement a structured feedback loop during lectures using quick polls or brief exercises to identify areas needing additional explanation",
        "Record and provide access to key concept explanations and worked examples, allowing students to review challenging material at their own pace",
        "Develop a repository of supplementary resources and practice materials that align with lecture content and assignment requirements"
      ]
    },
    assessment: {
      title: "Assessment & Feedback",
      items: [
        "Institute a systematic peer review process for assignments with clear rubrics and constructive feedback guidelines",
        "Implement regular progress check-ins during project development phases, providing early guidance and course correction opportunities",
        "Create a comprehensive exam preparation package including past papers, sample solutions, and targeted review materials",
        "Establish a transparent grading timeline with regular updates on assignment status and detailed feedback on areas for improvement"
      ]
    }
  };

  const dataSocietyImprovements = {
    courseStructure: {
      title: "Course Structure & Organization",
      items: [
        "Restructure course pacing to better distribute the 7.5hp workload, addressing current perception of 15hp intensity",
        "Maintain consistent instructor presence throughout the course duration to ensure topic continuity and learning progression",
        "Establish minimum 1-week buffer between critical lectures and related assignment deadlines, particularly for technical tools like KNIME",
        "Create dedicated Canvas modules for group assignments and project materials, separating from the people tab for better accessibility"
      ]
    },
    teachingSupport: {
      title: "Teaching & Learning Support",
      items: [
        "Expand ethics coverage beyond the initial two lectures, integrating ethical considerations throughout the course",
        "Incorporate more industry-standard tools (Power BI, Tableau, Click) alongside KNIME to enhance employability prospects",
        "Organize structured debates and discussions around data security strategies and protective measures",
        "Maintain the current coordinator's effective management style while strengthening overall course organization"
      ]
    },
    contentDelivery: {
      title: "Content Delivery",
      items: [
        "Distribute lecture materials and PowerPoint presentations minimum 48 hours before scheduled sessions",
        "Increase focus on real-world case studies and research examples, similar to the successful 'chili' example",
        "Balance theoretical concepts with practical applications in data privacy and GDPR compliance",
        "Create stronger connections between weekly topics despite instructor rotation"
      ]
    },
    assessment: {
      title: "Assessment & Feedback",
      items: [
        "Implement more balanced spacing between assignments, labs, and seminars to prevent overlap",
        "Release comprehensive assignment requirements earlier, especially when dependent on upcoming lecture content",
        "Ensure all critical learning materials are available well before related assignment deadlines",
        "Establish clear timeline for assignment preparation and submission phases"
      ]
    }
  };

  const overallProgramImprovements = {
    courseStructure: {
      title: "Program Structure & Organization",
      items: [
        "Implement centralized Canvas organization with dedicated assignment modules separate from people tab",
        "Establish fixed deadlines across all courses with minimum 2-week notice for any changes",
        "Create structured cross-course planning to prevent assignment overlap between AI and Data & Society courses",
        "Develop comprehensive study guides with clear expectations, deadlines, and resource links available from course start"
      ]
    },
    teachingSupport: {
      title: "Communication & Accessibility",
      items: [
        "Establish Discord presence for all instructors with defined response time expectations",
        "Implement 48-hour maximum response time policy for email inquiries",
        "Create dedicated office hours across courses for assignment clarification",
        "Organize regular informal fika events to strengthen program community and facilitate discussions"
      ]
    },
    contentDelivery: {
      title: "Learning Experience",
      items: [
        "Balance technical depth with adequate time for concept digestion and practical application",
        "Provide explicit learning outcomes mapping to industry requirements and competencies",
        "Create clear progression paths showing how courses build upon each other",
        "Establish comprehensive exam preparation guidelines with past papers and review materials"
      ]
    },
    assessment: {
      title: "Quality Assurance",
      items: [
        "Release complete project requirements and rubrics at course start",
        "Coordinate assignment schedules across courses to ensure manageable workload",
        "Implement systematic feedback collection and response mechanism",
        "Create transparent assessment criteria aligned with industry standards and expectations"
      ]
    }
  };
  const lastComment = {
    feedback: {
      title: "Additional Comments",
      items: [
        "Seasonal greetings from students for Christmas and New Year",
        "Request to restore AI course 100% report deadline to December 24th as originally planned",
        "Expression of gratitude for education quality, especially appreciated by international students",
        "No additional comments from remaining respondents"
      ]
    }
  };
  const courseMap = {
    'statistics': statisticsImprovements,
    'ai': aiImprovements,
    'data-society': dataSocietyImprovements,
    'overall-program': overallProgramImprovements,
    'last-comment': lastComment
  };

  const getCourseType = (type) => {
    if (type.toLowerCase().includes('statistics')) return 'statistics';
    if (type.toLowerCase().includes('ai')) return 'ai';
    if (type.toLowerCase().includes('data')) return 'data-society';
    if (type.toLowerCase().includes('overall')) return 'overall-program';
    if (type.toLowerCase().includes('last')) return 'last-comment';
    return 'statistics';
  };

  const improvements = courseMap[getCourseType(courseType)] || statisticsImprovements;

  return (
    <div className="improvement-areas">
      {Object.entries(improvements).map(([key, section]) => (
        <div key={key} className="improvement-section">
          <h4>{section.title}</h4>
          <ul>
            {section.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

const CourseSection = ({ title, questions }) => {
  const calculateStats = (responses) => {
    const ratings = [...responses].map(Number);
    const counts = Array(5).fill(0);
    ratings.forEach(r => {
      if (r >= 1 && r <= 5) counts[r-1]++;
    });
    
    const mean = ratings.reduce((a, b) => a + b, 0) / ratings.length;
    const sortedRatings = [...ratings].sort((a, b) => a - b);
    const median = sortedRatings[Math.floor(ratings.length / 2)];
    
    return {
      rating1: counts[0],
      rating2: counts[1],
      rating3: counts[2],
      rating4: counts[3],
      rating5: counts[4],
      mean: mean.toFixed(2),
      median,
      total: ratings.length
    };
  };

  return (
    <div className="course-section">
      <h1 className="feedback-title">{title}</h1>
      {questions.length > 0 && (
        <Section title="Rating Distribution">
          <div className="charts-container">
            {questions.map((q) => {
              const stats = calculateStats(q.responses);
              if (!stats) return null;

              const chartData = [{
                '1 Star': stats.rating1,
                '2 Stars': stats.rating2,
                '3 Stars': stats.rating3,
                '4 Stars': stats.rating4,
                '5 Stars': stats.rating5
              }];

              return (
                <div key={q.id} className="chart-wrapper">
                  <h4 className="question-text">{q.text}</h4>
                  <div className="stats-row">
                    <div className="stat-box">
                      <span className="stat-label">Responses</span>
                      <span className="stat-value">{stats.total}</span>
                    </div>
                    <div className="stat-box">
                      <span className="stat-label">Median</span>
                      <span className="stat-value">{stats.median}</span>
                    </div>
                    <div className="stat-box">
                      <span className="stat-label">Mean</span>
                      <span className="stat-value">{stats.mean}</span>
                    </div>
                  </div>
                  <div className="chart">
                    <ResponsiveContainer width="100%" height={250}>
                      <BarChart 
                        data={chartData} 
                        margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis hide />
                        <YAxis
                          tickLine={false}
                          axisLine={true}
                          label={{ 
                            value: 'Students',
                            angle: -90,
                            position: 'insideLeft',
                            offset: -5
                          }}
                        />
                        <Tooltip 
                          formatter={(value) => [`${value} students`, 'Responses']}
                        />
                        <Bar dataKey="1 Star" fill="#ef4444">
                          <LabelList dataKey="1 Star" position="top" />
                        </Bar>
                        <Bar dataKey="2 Stars" fill="#f97316">
                          <LabelList dataKey="2 Stars" position="top" />
                        </Bar>
                        <Bar dataKey="3 Stars" fill="#eab308">
                          <LabelList dataKey="3 Stars" position="top" />
                        </Bar>
                        <Bar dataKey="4 Stars" fill="#84cc16">
                          <LabelList dataKey="4 Stars" position="top" />
                        </Bar>
                        <Bar dataKey="5 Stars" fill="#22c55e">
                          <LabelList dataKey="5 Stars" position="top" />
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                    <div className="chart-legend">
                      {[
                        { label: '1 Star', color: '#ef4444' },
                        { label: '2 Stars', color: '#f97316' },
                        { label: '3 Stars', color: '#eab308' },
                        { label: '4 Stars', color: '#84cc16' },
                        { label: '5 Stars', color: '#22c55e' }
                      ].map(({ label, color }) => (
                        <div key={label} className="legend-item">
                          <span 
                            className="legend-color" 
                            style={{ backgroundColor: color }}
                            aria-hidden="true"
                          />
                          {label}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Section>
      )}
      <Section title="Key Areas for Improvement">
        <ImprovementAreas courseType={title.toLowerCase().includes('statistics') ? 'statistics' : title.toLowerCase().includes('ai') ? 'ai' : title.toLowerCase().includes('data') ? 'data-society' : title.toLowerCase().includes('overall') ? 'overall-program' : 'last-comment'} />
      </Section>
    </div>
  );
};

const StatisticsFeedback = () => {
  const statisticsCourseQuestions = [
    {
      id: 'stat_q1',
      text: 'The course provided a solid foundation in statistical theory and methods',
      responses: '554443443334435253255344'
    },
    {
      id: 'stat_q2',
      text: 'The practical exercises (labs, projects) effectively reinforced theoretical concepts',
      responses: '423253234343434233255455'
    },
    {
      id: 'stat_q3',
      text: 'The course materials were well-organized and easy to follow',
      responses: '124143223234235245244452'
    },
    {
      id: 'stat_q4',
      text: "The instructor's teaching style was effective (clear, engaging, supportive)",
      responses: '443121214232334432345353'
    },
    {
      id: 'stat_q5',
      text: 'The assignments were appropriate in difficulty and relevance',
      responses: '514352432353454233343453'
    },
    {
      id: 'stat_q6',
      text: 'The pace of the course was appropriate for the level of content being taught',
      responses: '542251411213214242244452'
    }
  ];

  const aiCourseQuestions = [
    {
      id: 'ai_q1',
      text: 'The AI and machine learning concepts covered so far are presented clearly and accessibly',
      responses: '432224312323414233243233'
    },
    {
      id: 'ai_q2',
      text: 'The current assignments are helping build practical skills in applying AI techniques',
      responses: '153421412344315215245244'
    },
    {
      id: 'ai_q3',
      text: 'The course materials were well-organized and easy to follow',
      responses: '333233431234214233353124'
    },
    {
      id: 'ai_q4',
      text: "The instructor's teaching style is effective (clear, engaging, supportive)",
      responses: '223122311223225233255134'
    },
    {
      id: 'ai_q5',
      text: 'The pace and difficulty of assignments are appropriate for learning',
      responses: '354432412254315313255333'
    }
  ];

  const dataSocietyCourseQuestions = [
    {
      id: 'ds_q1',
      text: 'The course is effectively introducing ethical implications of data science/AI',
      responses: '544545534344344453345543'
    },
    {
      id: 'ds_q2',
      text: "The initial topics provide good frameworks for thinking critically about data's role in society",
      responses: '533455533445345453345554'
    },
    {
      id: 'ds_q3',
      text: 'The course materials are well-organized and easy to follow',
      responses: '534354432344335453353554'
    },
    {
      id: 'ds_q4',
      text: "The instructor's teaching style is effective (clear, engaging, supportive)",
      responses: '554455442344335553344554'
    },
    {
      id: 'ds_q5',
      text: 'The initial pace and level of content are appropriate',
      responses: '533242552243334423344553'
    }
  ];

  const overallProgramQuestions = [
    {
      id: 'prog_q1',
      text: 'The program structure effectively combines technical skills with social considerations',
      responses: '434452432344344255145434'
    },
    {
      id: 'prog_q2',
      text: 'The courses so far are building a comprehensive understanding of data science',
      responses: '444454432344344255345333'
    }
  ];
  const lastComment = [
  ];

  return (
    <div className="statistics-feedback-wrapper">
      <div className="statistics-feedback-container">
        <CourseSection title="Statistics Course" questions={statisticsCourseQuestions} />
        <CourseSection title="AI for Data Science Course" questions={aiCourseQuestions} />
        <CourseSection title="Data and Society Course" questions={dataSocietyCourseQuestions} />
        <CourseSection title="Overall Program Evaluation" questions={overallProgramQuestions} />
        <CourseSection title="Additional Comments" questions={lastComment} />
      </div>
    </div>
  );
};

export default StatisticsFeedback;